
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.calendar {
  display: flex;
  .esmp-select + .esmp-select {
    margin-left: 10px;
  }
}
